import React from 'react';
import './AboutUsPage.css'; // CSS for styling

const AboutUsPage = () => {
  return (
    <div className="about-us-page">
      <header className="about-header">
        <h2>Welcome to Arpana Softwares Solutions</h2>
        <p>Your partner in innovative software solutions and career excellence.</p>
      </header>
      <section id="about" class="about section">

        <div class="container">

          <div class="row gy-4">

            <div class="col-lg-6 content" data-aos="fade-up" data-aos-delay="100">
              <p class="who-we-are">Who We Are</p>
              <h3>Shaping Futures with Dynamic Training and Advanced Software Solutions.</h3>
              <p >
                Arpana Softwares Solutions is a premier software development company and technical training center, dedicated to delivering top-notch IT solutions and equipping individuals with industry-ready skills. With a strong focus on innovation and career-building, we cater to businesses and aspiring professionals alike.
              </p>
              <ul>
                <li><i class="bi bi-check-circle"></i> <span>Providing innovative software solutions tailored to meet client-specific requirements.</span></li>
                <li><i class="bi bi-check-circle"></i> <span>Delivering industry-ready technical training for aspiring IT professionals.</span></li>
                <li><i class="bi bi-check-circle"></i> <span>Empowering businesses with robust and scalable IT services, ensuring seamless digital transformation.</span></li>
              </ul>

              <a href="#" class="read-more"><span>Read More</span><i class="bi bi-arrow-right"></i></a>
            </div>

            <div class="col-lg-6 about-images" data-aos="fade-up" data-aos-delay="200">
              <div class="row gy-4">
                <div class="col-lg-6">
                  <img src="assets/img/about-company-1.jpg" class="img-fluid" alt="" />
                </div>
                <div class="col-lg-6">
                  <div class="row gy-4">
                    <div class="col-lg-12">
                      <img src="assets/img/about-company-2.jpg" class="img-fluid" alt="" />
                    </div>
                    <div class="col-lg-12">
                      <img src="assets/img/about-company-3.jpg" class="img-fluid" alt="" />
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>
      </section>
      <div className="services-section">
        <h2>Our Expertise</h2>
        <div className="services-container">
          <div className="service-box">
            <h3>Software Development</h3>
            <p>
              We provide custom software solutions, mobile and web application development, and IT consulting services tailored to your business needs.
            </p>
          </div>
          <div className="service-box">
            <h3>Technical Training</h3>
            <p>
              Our training programs cover the latest technologies, including C/C++, Java, ReactJS, Angular, Mobile Apps Development, Full Stack Development, Python, Data Analytics, and Cloud Computing,
              to help individuals excel in their careers.
            </p>
          </div>
          <div className="service-box">
            <h3>Placement Assistance</h3>
            <p>
              We bridge the gap between talent and opportunity by offering resume-building, interview preparation, and strong corporate connections.
            </p>
          </div>
        </div>
      </div>

      <section className="contact-section">
        <h2>Our Mission</h2>
        <p className='our-mission-p'>
          Empowering businesses with innovative solutions and preparing individuals with the skills and knowledge required
          to thrive in the ever-evolving tech industry.
        </p>
      </section>
    </div>
  );
};

export default AboutUsPage;
