import React, { useState } from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';

const Navbar = () => {
   const [isMobileNavActive, setIsMobileNavActive] = useState(false);

  const handleMobileNavToggle = () => {
    setIsMobileNavActive(!isMobileNavActive);
    // Toggle the body class for background/body effect if needed
    document.body.classList.toggle('mobile-nav-active');
  };
  const closeMobileNav = () => {
    setIsMobileNavActive(false);
    document.body.classList.remove('mobile-nav-active');
  };
  return (
    <header id="header" className="header d-flex align-items-center fixed-top">
      <div className="container-fluid container-xl position-relative d-flex align-items-center">
        <a href="/" className="logo d-flex align-items-center me-auto">
          <img src="logo.png" alt="Logo" />
        </a>
        <nav
          id="navmenu"
          className={`navmenu ${isMobileNavActive ? 'mobile-nav-active' : ''}`}
        >
          <ul>
            <li><Link to="/" onClick={closeMobileNav}>Home</Link></li>
            <li><Link to="/aboutus" onClick={closeMobileNav}>About Us</Link></li>
            <li><Link to="/courses" onClick={closeMobileNav}>Courses</Link></li>
            <li><Link to="/services" onClick={closeMobileNav}>Services</Link></li>
            <li><Link to="/contactus" onClick={closeMobileNav}>Contact Us</Link></li>
          </ul>
        </nav>
        <i
          className={`mobile-nav-toggle d-xl-none bi ${
            isMobileNavActive ? 'bi-x' : 'bi-list'
          }`}
          onClick={handleMobileNavToggle}
        ></i>
      </div>
    </header>
  );
};

export default Navbar;
