import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./screens/home";
import Services from "./screens/services";
import ContactUs from "./screens/contactus";
import AboutUsPage from "./screens/aboutuspage";
import Footer from "./component/footer/Footer";
import Courses from "./screens/Courses";

export default function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/aboutus" element={<AboutUsPage />} />
        <Route path="/courses" element={<Courses />} />
      </Routes>
      <Footer />
    </div>
  );
}
