import React from 'react';
import './Services.css'; // Import CSS for styling

const courses = [
    {
        title: "C/C++",
        description: "Learn the basics of React, from components to state management, and build your first React app.",
        duration: "3 Months",
        price: "***",
    },
    {
        title: "Core Java",
        description: "Learn the basics of React, from components to state management, and build your first React app.",
        duration: "4 Months",
        price: "***",
    },
    {
        title: "Advanced Java",
        description: "Deep dive into advanced JavaScript concepts, including closures, async programming, and design patterns.",
        duration: "6 Months",
        price: "***",
    },
    {
        title: "Cloud Computing",
        description: "Master the basics of UI/UX design principles, wireframing, prototyping, and user research.",
        duration: "5 Months",
        price: "***",
    },
    {
        title: "Full Stack Development",
        description: "Learn to build mobile applications for both Android and iOS using React Native.",
        duration: "9 Months",
        price: "***",
    },
    {
        title: "Mobile App Development with React Native",
        description: "Learn to build mobile applications for both Android and iOS using React Native.",
        duration: "9 Months",
        price: "***",
    },

    {
        title: "Spring Boot",
        description: "Learn to build robust and scalable backend applications and RESTful APIs using Spring Boot, ideal for powering web and mobile platforms.",
        duration: "2 Months",
        price: "***",
    },
    {
        title: "Android App Development",
        description: "Learn to build mobile applications for both Android and iOS using React Native.",
        duration: "8 Months",
        price: "***",
    },
    {
        title: "iOS App Development",
        description: "Learn the basics of React, from components to state management, and build your first React app.",
        duration: "8 Months",
        price: "***",
    },
    {
        title: "JavaScript",
        description: "Deep dive into advanced JavaScript concepts, including closures, async programming, and design patterns.",
        duration: "3 Months",
        price: "***",
    },
    {
        title: "UI/UX Design Fundamentals",
        description: "Master the basics of UI/UX design principles, wireframing, prototyping, and user research.",
        duration: "2 Months",
        price: "***",
    },

    {
        title: "React JS",
        description: "Deep dive into advanced JavaScript concepts, including closures, async programming, and design patterns.",
        duration: "2 Months",
        price: "***",
    },


    {
        title: "Angular",
        description: "Deep dive into advanced JavaScript concepts, including closures, async programming, and design patterns.",
        duration: "2 Months",
        price: "***",
    },

];

const Courses = () => {
    return (
        <div className='services-page'>
            <section id="courses" class="course section">
                <div class="container section-title" data-aos="fade-up">
                    <h2>Our Courses</h2>
                    <p>Transform Your Future with Courses Designed to Inspire and Empower</p>
                </div>
                <div class="container">
                    <div class="row gy-3">
                        {courses.map((course, index) => (
                            <div class="col-lg-3" data-aos="zoom-in" data-aos-delay="100">
                                <div class="course-item">
                                    <h2>{course.title}</h2>
                                    <p class="description">{course.description}</p>
                                    <div className="course-details">
                                        <span className="course-duration">{course.duration}</span>
                                        <span className="course-price">{course.price}</span>
                                    </div>
                                    
                                </div>
                            </div>
                        ))};
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Courses;
