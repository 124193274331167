import React, { useState } from 'react';
import './ContactUs.css'; // Import the CSS for styling

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission (e.g., send the form data to a backend API or email)
    alert('Message sent!');
  };

  return (
    <div className="contact-us-page">
      <section id="contact" class="contact section">
        <div class="container section-title" data-aos="fade-up">
          <h2>Contact Us</h2>
          <p>If you have any questions, feel free to reach out to us via the form below.</p>
        </div>
        <div class="container" data-aos="fade-up" data-aos-delay="100">
          <div class="row gy-4">
            <div class="col-lg-6">
              <div class="info-item d-flex flex-column justify-content-center align-items-center" data-aos="fade-up" data-aos-delay="200">
                <i class="bi bi-geo-alt"></i>
                <h3>Address</h3>
                <p> 1st Floor Samta Palace Near Mind's Eye International School Englishpura Sehore (M.P.)</p>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="info-item d-flex flex-column justify-content-center align-items-center" data-aos="fade-up" data-aos-delay="300">
                <i class="bi bi-telephone"></i>
                <h3>Call Us</h3>
                <p>+91-6267117249</p>
              </div>
            </div>

            <div class="col-lg-3 col-md-6">
              <div class="info-item d-flex flex-column justify-content-center align-items-center" data-aos="fade-up" data-aos-delay="400">
                <i class="bi bi-envelope"></i>
                <h3>Email Us</h3>
                <p>msureshmewara@gmail.com</p>
              </div>
            </div>

          </div>
          <div class="row gy-4 mt-1">
            <div class="col-lg-6" data-aos="fade-up" data-aos-delay="300">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3667.0420100604542!2d77.09088747514626!3d23.205139579046172!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x397cf3c992255b4d%3A0xa830dee046763fd6!2sSamta%20Palace%20Bhopal%20Naka!5e0!3m2!1sen!2sin!4v1732463462567!5m2!1sen!2sin"
                frameBorder="0"
                style={{ border: 0, width: '100%', height: '400px' }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Google Maps Embed"
              ></iframe>  </div>

            <div class="col-lg-6">
              <form class="php-email-form" data-aos="fade-up" data-aos-delay="400">
                <div class="row gy-4">

                  <div class="col-md-6">
                    <input type="text" name="name" class="form-control" placeholder="Your Name" required="" />
                  </div>
                  <div class="col-md-6 ">
                    <input type="email" class="form-control" name="email" placeholder="Your Email" required="" />
                  </div>
                  <div class="col-md-12">
                    <input type="text" class="form-control" name="subject" placeholder="Subject" required="" />
                  </div>
                  <div class="col-md-12">
                    <textarea class="form-control" name="message" rows="6" placeholder="Message" required=""></textarea>
                  </div>
                  <div class="col-md-12 text-center">
                    <div class="loading">Loading</div>
                    <div class="error-message"></div>
                    <div class="sent-message">Your message has been sent. Thank you!</div>
                    <button type="submit">Send Message</button>
                  </div>
                </div>
              </form>
            </div>

          </div>

        </div>

      </section>
    </div>
  );
};

export default ContactUs;
