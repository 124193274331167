import React from 'react';
import './Services.css'; // Import CSS for styling

const services = [
  {
    title: "Web Development",
    description: "We build responsive and scalable websites using modern technologies like React, Node.js, and more.",
  },
  {
    title: "Mobile App Development",
    description: "Design and develop feature-rich mobile apps for iOS, Android, and cross-platform, ensuring excellent performance and user experience.",
  },
  {
    title: "Digital Marketing",
    description: "Our digital marketing services include SEO, SEM, content marketing, and social media strategies to grow your brand.",
  },
  {
    title: "UI/UX Design",
    description: "Design intuitive user interfaces and engaging user experiences to enhance usability and customer satisfaction.",
  },
  {
    title: "Custom Software Development",
    description: "Build tailored software solutions to meet unique business needs, from web and desktop applications to enterprise systems.",
  },
  {
    title: "DevOps Services",
    description: "Implement CI/CD pipelines, infrastructure automation, and cloud management to streamline development and operations.",
  },
  {
    title: "Cloud Solutions",
    description: "Offer cloud migration, infrastructure setup, and SaaS development for scalable, secure, and cost-effective business operations.",
  },
  {
    title: "AI & Machine Learning Solutions",
    description: "Build intelligent systems with AI/ML for predictive analytics, natural language processing, and data-driven decision-making.",
  },
  {
    title: "IT Consulting",
    description: "Provide expert advice on technology strategy, system architecture, and project management to drive digital transformation.",
  },
  {
    title: "Cybersecurity Services",
    description: "Deliver end-to-end cybersecurity solutions, including vulnerability assessments, data protection, and threat management.",
  },
  {
    title: "API Development & Integration",
    description: "Design, develop, and integrate APIs for seamless connectivity between systems and services.",
  },
  {
    title: "Quality Assurance & Testing",
    description: "Provide manual and automated testing to ensure software reliability, security, and performance.",
  }
];

const Services = () => {
  return (
    <div className="services-page">
      <div id="services" class="services section light-background">
        <div class="container section-title" data-aos="fade-up">
          <h2>Our Services</h2>
          <p>Empowering Innovation Through Cutting-Edge Software Solutions</p>
        </div>
        <div class="container">
          <div class="row g-5">
            {services.map((service, index) => (
              <div class="col-lg-6" data-aos="fade-up" data-aos-delay="100" key={index}>
                <div class="service-item item-cyan position-relative">
                  <i class="bi bi-activity icon"></i>
                  <div>
                    <h3>{service.title}</h3>
                    <p>{service.description}</p>
                    <a href="#" class="read-more stretched-link">Learn More <i class="bi bi-arrow-right"></i></a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
