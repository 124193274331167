import '../App.css';
import React, { useState } from 'react';
import './home.css'; // Import CSS for styling
import Services from './services';
import Courses from './Courses';
import ContactUs from './contactus';


const Home = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    // Toggle the active index
    if (activeIndex === index) {
      setActiveIndex(null); // If clicked again, deactivate
    } else {
      setActiveIndex(index); // Set clicked item as active
    }
  };
  const faqs = [
    {
      question: "What courses do you offer?",
      answer:
        "We offer a variety of courses across different subjects including software development, data science, AI, digital marketing, and more. Explore our course catalog to find the right course for you.",
    },
    {
      question: "How can I enroll in a course?",
      answer:
        "To enroll in a course, simply visit our course page, select the course you're interested in, and click the 'Enroll Now' button. Follow the instructions to complete your registration.",
    },
    {
      question: "What is the duration of the courses?",
      answer:
        "The duration of each course varies. Some courses are self-paced and can be completed in a few weeks, while others may take a few months to finish depending on the level of difficulty and the learning path.",
    },
    {
      question: "Can I get a certificate after completing a course?",
      answer:
        "Yes, we provide certificates for most of our courses upon successful completion. The certificate will be issued digitally and can be shared on your LinkedIn profile or downloaded for your records.",
    },
    {
      question: "Do you offer live sessions or is everything recorded?",
      answer:
        "We offer a combination of recorded lectures, live sessions, and interactive Q&A webinars. Live sessions are scheduled regularly and are a great opportunity to interact with instructors and peers.",
    },
    // Software solutions related questions
    {
      question: "What software solutions do you provide?",
      answer:
        "We provide custom software solutions tailored to your business needs. These include enterprise resource planning (ERP), customer relationship management (CRM), project management tools, and more. We work with you to create the best-fit solution for your organization.",
    },
    {
      question: "How do I get started with a custom software solution?",
      answer:
        "To get started with a custom software solution, you can schedule a consultation with our team. We'll assess your requirements, propose a solution, and guide you through the development and implementation process.",
    },
    {
      question: "Can you integrate your software with existing systems?",
      answer:
        "Yes, our software solutions are designed to integrate seamlessly with existing systems such as accounting software, payment gateways, CRM systems, and more, ensuring a smooth transition and improved workflow.",
    },
    {
      question: "What is the cost of custom software development?",
      answer:
        "The cost of custom software development varies depending on the complexity, features, and scope of the project. We offer flexible pricing models and will provide a detailed proposal based on your specific requirements.",
    },
    {
      question: "How long does it take to develop a custom software solution?",
      answer:
        "The timeline for custom software development depends on the scope and complexity of the project. On average, development can take anywhere from a few weeks to several months. We will provide a detailed project timeline once we understand your requirements.",
    },
    {
      question: "Do you offer ongoing support after the software is deployed?",
      answer:
        "Yes, we provide ongoing support and maintenance after the software is deployed. This includes bug fixes, updates, and any enhancements needed to ensure the software continues to meet your business needs.",
    },
  ];


  return (
    <>
      <div class="main">
        <section id="hero" class="hero section">
          <div class="hero-bg">
            <img src="assets/img/hero-bg-light.webp" alt="" />
          </div>
          <div class="container text-center">
            <div class="d-flex flex-column justify-content-center align-items-center">
              <h1 data-aos="fade-up">Welcome to <span>Arpana Softwares Solutions</span></h1>
              <p data-aos="fade-up" data-aos-delay="100">Your partner in innovative software solutions and career excellence.<br /></p>
              <div class="d-flex" data-aos="fade-up" data-aos-delay="200">
                <a href="#about" class="btn-get-started">Get Started</a>
                <a href="/" class="glightbox btn-watch-video d-flex align-items-center"><i class="bi bi-play-circle"></i><span>Watch Video</span></a>
              </div>
              <img src="assets/img/hero-services-img.webp" class="img-fluid hero-img" alt="" data-aos="zoom-out" data-aos-delay="300" />
            </div>
          </div>

        </section>
        <section id="featured-services" class="featured-services section  ">

          <div class="container">

            <div class="row gy-4">

              <div class="col-xl-4 col-lg-6" data-aos="fade-up" data-aos-delay="100">
                <div class="service-item d-flex">
                  <div class="icon flex-shrink-0"><i class="bi bi-briefcase"></i></div>
                  <div>
                    <h4 class="title"><a href="#" class="stretched-link">Cost-Effective Cloud Migration</a></h4>
                    <p class="description">Transition smoothly to the cloud with minimal disruption and optimized cost planning.</p>
                  </div>
                </div>
              </div>


              <div class="col-xl-4 col-lg-6" data-aos="fade-up" data-aos-delay="200">
                <div class="service-item d-flex">
                  <div class="icon flex-shrink-0"><i class="bi bi-card-checklist"></i></div>
                  <div>
                    <h4 class="title"><a href="#" class="stretched-link">Online Training</a></h4>
                    <p class="description">We provide Online Trainings on latest IT Courses from Industry Experts, who provide Real-Time Guidance, Case Studies and Projects.</p>
                  </div>
                </div>
              </div>

              <div class="col-xl-4 col-lg-6" data-aos="fade-up" data-aos-delay="300">
                <div class="service-item d-flex">
                  <div class="icon flex-shrink-0"><i class="bi bi-bar-chart"></i></div>
                  <div>
                    <h4 class="title"><a href="#" class="stretched-link">Continuous Learning Resources</a></h4>
                    <p class="description">Access post-workshop resources, such as guides, videos, and tools, to continue skill improvement beyond the sessions.</p>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </section>
        <section id="about" class="about section">

          <div class="container">

            <div class="row gy-4">

              <div class="col-lg-6 content" data-aos="fade-up" data-aos-delay="100">
                <p class="who-we-are">Who We Are</p>
                <h3>Your partner in innovative software solutions and career excellence.</h3>
                <p class="fst-italic">
                  Arpana Softwares Solutions is a premier software development company and technical training center, dedicated to delivering top-notch IT solutions and equipping individuals with industry-ready skills. With a strong focus on innovation and career-building, we cater to businesses and aspiring professionals alike.
                </p>
                <ul>
                  <li><i class="bi bi-check-circle"></i> <span>Bridging the gap between academia and industry through practical training programs.</span></li>
                  <li><i class="bi bi-check-circle"></i> <span>Offering end-to-end software development services to accelerate business growth.</span></li>
                  <li><i class="bi bi-check-circle"></i> <span>Fostering a culture of continuous learning and technological excellence.</span></li>
                </ul>

                <a href="#" class="read-more"><span>Read More</span><i class="bi bi-arrow-right"></i></a>
              </div>

              <div class="col-lg-6 about-images" data-aos="fade-up" data-aos-delay="200">
                <div class="row gy-4">
                  <div class="col-lg-6">
                    <img src="assets/img/about-company-1.jpg" class="img-fluid" alt="" />
                  </div>
                  <div class="col-lg-6">
                    <div class="row gy-4">
                      <div class="col-lg-12">
                        <img src="assets/img/about-company-2.jpg" class="img-fluid" alt="" />
                      </div>
                      <div class="col-lg-12">
                        <img src="assets/img/about-company-3.jpg" class="img-fluid" alt="" />
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>

          </div>
        </section>
        <section id="clients" class="clients section">

          <div class="container" data-aos="fade-up">

            <div class="row gy-4">

              <div class="col-xl-2 col-md-3 col-6 client-logo">
                <img src="assets/img/clients/client-1.png" class="img-fluid" alt="" />
              </div>

              <div class="col-xl-2 col-md-3 col-6 client-logo">
                <img src="assets/img/clients/client-2.png" class="img-fluid" alt="" />
              </div>
              <div class="col-xl-2 col-md-3 col-6 client-logo">
                <img src="assets/img/clients/client-3.png" class="img-fluid" alt="" />
              </div>

              <div class="col-xl-2 col-md-3 col-6 client-logo">
                <img src="assets/img/clients/client-4.png" class="img-fluid" alt="" />
              </div>

              <div class="col-xl-2 col-md-3 col-6 client-logo">
                <img src="assets/img/clients/client-5.png" class="img-fluid" alt="" />
              </div>

              <div class="col-xl-2 col-md-3 col-6 client-logo">
                <img src="assets/img/clients/client-6.png" class="img-fluid" alt="" />
              </div>

            </div>

          </div>

        </section>
        <section id="features" class="features section">


          <div class="container section-title" data-aos="fade-up">
            <h2>Features</h2>
            <p>Empowering Businesses and Careers with Innovative IT Solutions</p>
          </div>

          <div class="container">
            <div class="row justify-content-between">

              <div class="col-lg-5 d-flex align-items-center">

                <ul class="nav nav-tabs" data-aos="fade-up" data-aos-delay="100">
                  <li class="nav-item">
                    <a class="nav-link active show" data-bs-toggle="tab" data-bs-target="#features-tab-1">
                      <i class="bi bi-binoculars"></i>
                      <div>
                        <h4 class="d-none d-lg-block">Industry-Ready Training Programs</h4>
                        <p>
                          Our training programs focus on practical, hands-on skills that align with market demands, helping individuals step into the tech industry with confidence.
                        </p>
                      </div>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="tab" data-bs-target="#features-tab-2">
                      <i class="bi bi-box-seam"></i>
                      <div>
                        <h4 class="d-none d-lg-block">Custom Software Development</h4>
                        <p>
                          We specialize in creating tailored IT solutions designed to address the unique challenges and goals of businesses, ensuring seamless integration and efficiency.
                        </p>
                      </div>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="tab" data-bs-target="#features-tab-3">
                      <i class="bi bi-brightness-high"></i>
                      <div>
                        <h4 class="d-none d-lg-block">Comprehensive IT Services</h4>
                        <p>
                          From development to deployment, we provide end-to-end IT services that cater to diverse industry needs, ensuring reliable and scalable solutions.
                        </p>
                      </div>
                    </a>
                  </li>
                </ul>

              </div>

              <div class="col-lg-6">

                <div class="tab-content" data-aos="fade-up" data-aos-delay="200">

                  <div class="tab-pane fade active show" id="features-tab-1">
                    <img src="assets/img/tabs-1.jpg" alt="" class="img-fluid" />
                  </div>

                  <div class="tab-pane fade" id="features-tab-2">
                    <img src="assets/img/tabs-2.jpg" alt="" class="img-fluid" />
                  </div>

                  <div class="tab-pane fade" id="features-tab-3">
                    <img src="assets/img/tabs-3.jpg" alt="" class="img-fluid" />
                  </div>
                </div>

              </div>

            </div>

          </div>

        </section>
        <section id="features-details" class="features-details section">

          <div class="container">

            <div class="row gy-4 justify-content-between features-item">

              <div class="col-lg-6" data-aos="fade-up" data-aos-delay="100">
                <img src="assets/img/features-1.jpg" class="img-fluid" alt="" />
              </div>

              <div class="col-lg-5 d-flex align-items-center" data-aos="fade-up" data-aos-delay="200">
                <div class="content">
                  <h3>Mobile and Web Application Development</h3>
                  <p>
                    We design and develop user-friendly, high-performance mobile and web applications tailored to your business needs, ensuring seamless functionality and a superior user experience across all devices.
                  </p>
                  <a href="#" class="btn more-btn">Learn More</a>
                </div>
              </div>

            </div>

            <div class="row gy-4 justify-content-between features-item">

              <div class="col-lg-5 d-flex align-items-center order-2 order-lg-1" data-aos="fade-up" data-aos-delay="100">

                <div class="content">
                  <h3>Skill Development Workshops</h3>
                  <p>
                    Participate in workshops that enhance both technical skills and soft skills, such as teamwork and communication.
                  </p>
                  <ul>
                    <li><i class="bi bi-easel flex-shrink-0"></i> Hands-on training for technical and soft skills enhancement.</li>
                    <li><i class="bi bi-patch-check flex-shrink-0"></i> Real-world projects to apply skills effectively.</li>
                    <li><i class="bi bi-brightness-high flex-shrink-0"></i> Builds confidence for workplace success.</li>
                  </ul>
                  <p></p>
                  <a href="#" class="btn more-btn">Learn More</a>
                </div>

              </div>

              <div class="col-lg-6 order-1 order-lg-2" data-aos="fade-up" data-aos-delay="200">
                <img src="assets/img/features-2.jpg" class="img-fluid" alt="" />
              </div>

            </div>

          </div>

        </section>
        {/* Our Services */}
        <Services />
        <section id="more-features" class="more-features section">

          <div class="container">

            <div class="row justify-content-around gy-4">

              <div class="col-lg-6 d-flex flex-column justify-content-center order-2 order-lg-1" data-aos="fade-up" data-aos-delay="100">
                <h3>Cutting-Edge Software and Cloud Technology Services</h3>
                <p>Delivering innovative software solutions and advanced cloud technologies to optimize business performance and scalability.</p>

                <div class="row">

                  <div class="col-lg-6 icon-box d-flex">
                    <i class="bi bi-easel flex-shrink-0"></i>
                    <div>
                      <h4>Scalable Cloud Infrastructure</h4>
                      <p>Deploy applications on a robust cloud platform, ensuring high availability, scalability, and performance to meet growing business demands. </p>
                    </div>
                  </div>

                  <div class="col-lg-6 icon-box d-flex">
                    <i class="bi bi-patch-check flex-shrink-0"></i>
                    <div>
                      <h4>Secure Data Management</h4>
                      <p>Implement advanced data encryption and backup strategies to safeguard sensitive information and ensure seamless recovery.</p>
                    </div>
                  </div>

                  <div class="col-lg-6 icon-box d-flex">
                    <i class="bi bi-brightness-high flex-shrink-0"></i>
                    <div>
                      <h4>Custom Software Solutions</h4>
                      <p>Develop tailor-made applications that align with your business processes, enhancing productivity and efficiency.</p>
                    </div>
                  </div>

                  <div class="col-lg-6 icon-box d-flex">
                    <i class="bi bi-brightness-high flex-shrink-0"></i>
                    <div>
                      <h4>IoT and AI Integration</h4>
                      <p>Harness the power of IoT and AI within cloud frameworks for innovative and automated solutions.</p>
                    </div>
                  </div>

                </div>

              </div>

              <div class="features-image col-lg-5 order-1 order-lg-2" data-aos="fade-up" data-aos-delay="200">
                <img src="assets/img/features-3.jpg" alt="" />
              </div>

            </div>

          </div>

        </section>
        {/* Our Courses page*/}
        <Courses />
        <section id="faq" className="faq section">
          <div className="container section-title" data-aos="fade-up">
            <h2>Frequently Asked Questions</h2>
          </div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10" data-aos="fade-up" data-aos-delay="100">
                <div className="faq-container">
                  {faqs.map((faq, index) => (
                    <div
                      key={index}
                      className={`faq-item ${activeIndex === index ? "faq-active" : ""}`}
                    >
                      <h3 onClick={() => handleToggle(index)}>{faq.question}</h3>
                      <div className="faq-content">
                        <p>{faq.answer}</p>
                      </div>
                      <i className="faq-toggle bi bi-chevron-right"></i>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Contact us page */}
        <ContactUs />
      </div>
    </>
  );
};

export default Home;
