import React from "react";
import "./Footer.css";
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer id="footer" className="footer position-relative light-background">
      <div className="container footer-top">
        <div className="row gy-4">
          {/* Footer About */}
          <div className="col-lg-4 col-md-6 footer-about">
            <Link to="/" className="logo d-flex align-items-center">
              <span className="sitename">Arpana Softwares Solutions</span>
            </Link>
            <div className="footer-contact pt-3">
              <p>1st Floor Samta Palace Near Mind's Eye International School Englishpura</p>
              <p>Sehore (M.P.)</p>
              <p className="mt-3">
                <strong>Phone:</strong> <span>+91-6267117249</span>
              </p>
              <p>
                <strong>Email:</strong> <span>msureshmewara@gmail.com</span>
              </p>
            </div>
            <div className="social-links d-flex mt-4">
              <Link to="#"><i className="bi bi-twitter-x"></i></Link>
              <a to="#"><i className="bi bi-facebook"></i></a>
              <a to="#"><i className="bi bi-instagram"></i></a>
              <a to="#"><i className="bi bi-linkedin"></i></a>
            </div>
          </div>

          {/* Useful Links */}
          <div className="col-lg-2 col-md-3 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/aboutus">About us</Link></li>
              <li><Link to="/services">Services</Link></li>
              <li><Link to="#">Terms of service</Link></li>
              <li><Link to="#">Privacy policy</Link></li>
            </ul>
          </div>

          {/* Our Services */}
          <div className="col-lg-2 col-md-3 footer-links">
            <h4>Our Services</h4>
            <ul>
              <li><Link to="/services">Web Design</Link></li>
              <li><Link to="/services">Web Development</Link></li>
              <li><Link to="/services">Mobile App Development</Link></li>
              <li><Link to="/services">Product Management</Link></li>
              <li><Link to="/services">Digital Marketing</Link></li>
            </ul>
          </div>

          {/* Newsletter Section */}
          <div className="col-lg-4 col-md-12 footer-newsletter">
            <h4>Our Newsletter</h4>
            <p>Subscribe to our newsletter and receive the latest news about our products and services!</p>
            <form className="php-email-form">
              <div className="newsletter-form">
                <input type="email" name="email" placeholder="Enter your email" />
                <input type="submit" value="Subscribe" />
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="container copyright text-center mt-4">
        <p>
          © <span>Copyright</span>{" "}
          <strong className="px-1 sitename">Arpana Softwares Solutions</strong>
          <span>All Rights Reserved</span>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
